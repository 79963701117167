import { ProgramsHero } from "../components/Programs-Hero"
import { BecomeOneOfUs } from "../components/Become-One-Of-Us"
import { SpiritualiTeaPanel } from "../components/panels/Spirituali-Tea-Panel"
import { KenyaPanel } from "../components/panels/Kenya-Panel"
import { AlignPrograms } from "../components/Align-Programs"
import { ProgramsPanels } from "../components/Programs-Panels"
import { Seo } from "../components/Seo"
import { AdPanel } from "../components/panels/Ad-Panel"
import { graphql } from "gatsby"
import React, { useContext, useState } from "react"
import { DefaultLayout } from "../layout/DefaultLayout"
import { AdPanelNoSlide } from "../components/panels/Ad-Panel-No-Slide"
import { siteContext } from "../layout/SiteContextProvider"

const Programs = ({ data }) => {
  const [hovered, setHovered] = useState(false)
  const { toolTipMobile, setToolTipMobile } = useContext(siteContext)

  const expiration_normalizer = items => {
    let ads_array = []

    items.forEach(item => {
      if (
        !item.node.frontmatter.expiration_date ||
        item.node.frontmatter.expiration_date == ""
      ) {
        ads_array.push(item)
      } else {
        let expirationDate = new Date(item.node.frontmatter.expiration_date)
        let currentDate = new Date()
        if (expirationDate > currentDate) {
          ads_array.push(item)
        }
      }
    })
    return ads_array
  }

  const non_expired_ads = expiration_normalizer(data.ads.edges)
  return (
    <>
      <Seo
        title="We invest in individual & community cultivation"
        description="MLIFE blends spiritually rooted holistic programs at the intersection of technology, education, and arts to cultivate potential change-makers living in the margins."
      />
      <ProgramsHero />
      <SpiritualiTeaPanel
        toolTipMobile={toolTipMobile}
        setToolTipMobile={setToolTipMobile}
        hovered={hovered}
        setHovered={setHovered}
      />
      {/* <KenyaPanel /> */}
      {non_expired_ads.length > 1 ? (
        <AdPanel ads={non_expired_ads} />
      ) : (
        <AdPanelNoSlide ads={non_expired_ads} />
      )}
      <AlignPrograms id="overview" />
      <ProgramsPanels />
      <BecomeOneOfUs />
    </>
  )
}

export const query = graphql`
  query AllAds {
    ads: allMarkdownRemark(
      filter: {
        frontmatter: {
          ad: { eq: true }
          title: { ne: "DUMMY AD (DO NOT DELETE)" }
        }
      }
      sort: { fields: frontmatter___date, order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            blurb
            description
            ad
            videolink
            title
            modalbuttontext
            modalbuttonlink
            slug
            expiration_date
            featured_image {
              childrenImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE)
              }
            }
            dates {
              displayformat
              seasondates {
                seasonend
                seasonstart
                seasonyearstart
                seasonyearend
              }
              monthdates {
                monthend
                monthstart
                yearend
                yearstart
              }
              specificdates {
                specificenddate(formatString: "MMM DD, YYYY")
                specificstartdate(formatString: "MMM DD, YYYY")
              }
            }
          }
          html
        }
      }
    }
  }
`

export default Programs
