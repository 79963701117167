import styled from "@emotion/styled"
import React from "react"
import { fonts, colors, device } from "./layout/GlobalStyles"
import rapidLogo from "../images/programs/logo-rapid.svg"
import ecclesiaLogo from "../images/programs/logo-ecclesia.svg"
import mloveLogo from "../images/programs/logo-mlove.svg"
import leapLogo from "../images/programs/logo-leap.svg"
import spacesLogo from "../images/programs/logo-spaces.svg"
import { Link } from "gatsby"

const MainContainer = styled.div`
  padding: 62px 0px 62px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #e8f0ef;

  a {
    color: black;
  }
  h4 {
    font-size: 42px;
    margin: 25px 0px 15px;
    font-weight: 800;
    margin-top: 0px;
    letter-spacing: -1.88px;
    line-height: 45px;
  }

  h4 + p {
    color: ${colors.dark3};
    font-size: 30px;
    margin-bottom: 70px;
    font-weight: 900;
    letter-spacing: -0.94px;
    line-height: 36px;
  }
  @media ${device.tablet} {
    h4 {
      line-height: 72px;
      font-size: 60px;
      margin-bottom: 5px;
    }
  }
  @media ${device.laptop} {
    padding: 110px 0px 108px;
    p {
      font-size: 30px;
    }
    h4 {
      margin-bottom: -20px;
      font-size: 60px;
    }
    h4 + p {
      margin-bottom: 60px;
    }
  }
`

const Panel = styled.div`
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 8px 64px 0 rgba(0, 0, 0, 0.05);
  border: 3px solid #fff;
  padding: 40px 25px 30px;
  margin: 25px 0px;
  transition: box-shadow 0.2s ease-in;
  h4 + p {
    color: ${colors.dark3};
    font-size: 24px;
    margin: 0em;
    font-weight: 900;
    letter-spacing: -0.94px;
    line-height: 36px;
  }
  &:hover {
    border: 3px solid #16675a;
    box-shadow: none;
    background: ${colors.secondary203};
  }
  h5 {
    margin: 25px 0px 15px 0px;
  }
  p {
    font-weight: 400;
    font-size: 22px;
  }
  p:first-of-type {
    font-weight: 900;
  }
  h5 {
    font-family: ${fonts.secondary};
    color: ${colors.primary2};
    font-size: 30px;
    letter-spacing: -0.94px;
    line-height: 40px;
    span {
      font-weight: 800;
    }
  }
  @media ${device.laptop} {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px 42px 70px;
    width: 1030px;

    p {
      font-size: 22px;
      margin: 0px 0px 10px;
    }
    p:first-of-type {
      font-size: 22px;
      font-weight: 900;
      letter-spacing: -0.69px;
      line-height: 36px;
      width: 648px;
    }
    .mlove-logo {
      margin-left: 35px;
      width: 138.48px;
      position: relative;
      left: 5px;
    }
    .rapid-logo {
      margin-left: 45px;
      width: 119px;
    }
    .ecclesia-logo {
      margin-left: 25px;
      width: 140px;
      position: relative;
      left: 10px;
    }
    .leap-logo {
      position: relative;
      left: 5px;
      width: 166px;
    }

    .spaces-logo {
      margin-right: 20px;
      min-width: 248px;
      position: relative;
      left: 15px;
    }
    .image {
    }
    .text-container {
      position: relative;
      left: 15px;
      color: ${colors.dark3};
      max-width: 658px;
    }
    .spaces-text {
      margin-right: 40px;
    }
  }
  @media ${device.laptopL} {
    max-width: 1112px;
  }
`

export const ProgramsPanels = () => {
  return (
    <MainContainer>
      <div className="container">
        <h4>Programs</h4>
        <p>Individual stage-specific and community cultivation.</p>
        <Link to="/programs/leap/">
          <Panel>
            <img className="leap-logo image" src={leapLogo} alt="leap logo" />
            <div className="text-container">
              <h5>
                <span>LEAP</span> - Learning Enrichment <br />& Acceleration
                Program
              </h5>
              <p>Age-specific - Children and transitional youths ages 5-20</p>
              <p>
                Through our Newspaper in Education (NIE) initiative, LEAP
                leverage the New York Times and other periodicals to support
                under-resourced students experiencing learning poverty to move
                from "learning to read" to "reading to learn."{" "}
              </p>
            </div>
          </Panel>
        </Link>
        <Link to="/programs/mlove/">
          <Panel>
            <img
              className="mlove-logo image"
              src={mloveLogo}
              alt="mlove logo"
            />
            <div className="text-container">
              <h5>
                <span>MLOVE</span> - Leaders of Openness, Virtue, and Empathy
              </h5>
              <p>Age-specific - Young adults ages 21-34 </p>
              <p>
                We are a leadership incubator program for high-impact,
                high-potential NextGen leaders. We provide them the space and
                resources to collaborate, build their social capital, and learn
                how to lead with love from a world-class faculty.
              </p>
            </div>
          </Panel>
        </Link>
        <Link to="/programs/rapid/">
          <Panel>
            <img
              className="rapid-logo image"
              src={rapidLogo}
              alt="rapid logo"
            />
            <div className="text-container">
              <h5>
                <span>RAPID</span> - Relief Aid for People in Despair
              </h5>
              <p>Community - All ages & people welcome</p>
              <p>
                We invest in bold solutions that tackle poverty and emerging
                crises by providing unconditional small cash grants to
                economically disadvantaged families in despair and investment
                grants to solutionists creating change in their communities.
              </p>
            </div>
          </Panel>
        </Link>
        <Link to="/programs/ecclesia/">
          <Panel>
            <img
              className="ecclesia-logo image"
              src={ecclesiaLogo}
              alt="ecclesia logo"
            />
            <div className="text-container">
              <h5>
                <span>Ecclesia</span>
              </h5>
              <p>Community - All ages & people welcome </p>
              <p>
                We provide a radical, welcoming, and affirming space to explore
                spirituality (or not) for those who scarcely believe they
                belong--the disenfranchised, the discriminated against, and
                those locked in poverty to break bread and find a sabbath rest.
              </p>
            </div>
          </Panel>
        </Link>
        <Link to="/programs/spaces-of-grace/">
          <Panel>
            <img
              className="spaces-logo image"
              src={spacesLogo}
              alt="spaces logo"
            />
            <div className="spaces-text text-container">
              <h5>
                <span>Spaces of Grace</span>
              </h5>
              <p>Community - Anyone 21+</p>
              <p>
                We are a healing and safe space for those struggling with shame,
                rejection, life stressors, or trauma to find a relational
                container for self-work, connection, and learning through peer
                support, human relationships, support groups, and counseling.
              </p>
            </div>
          </Panel>
        </Link>
      </div>
    </MainContainer>
  )
}
