import styled from "@emotion/styled"
import React from "react"
import { fonts, device } from "./layout/GlobalStyles"
import bannerGraphic from "../images/stage-community.svg"

const HeroContainer = styled.div`
  padding: 54px 0px 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    color: #16675a;
    font-family: ${fonts.secondary};
    font-weight: 300;
    font-size: 36px;
    font-weight: 300;
    letter-spacing: -1.88px;
    line-height: 48px;
  }
  img {
    width: 320px;
  }
  @media ${device.tablet} {
    flex-direction: row;
  }
  @media ${device.laptop} {
    justify-content: space-between;
    padding: 145px 0px 128px;
    max-width: 942px;
    h1 {
      max-width: 380px;
      font-size: 60px;
      letter-spacing: -1.88px;
      line-height: 65px;
    }
    img {
      width: 463.63px;
    }
  }
  @media ${device.laptopL} {
    justify-content: space-between;
    max-width: 1112px;
    padding: 145px 0px 128px;
    h1 {
      max-width: 380px;
      font-size: 60px;
    }
    img {
      width: 563.63px;
    }
  }
`

export const ProgramsHero = () => {
  return (
    <HeroContainer className="container">
      <h1>We invest in individual & community cultivation</h1>
      <img src={bannerGraphic} alt="animated children and group of people" />
    </HeroContainer>
  )
}
