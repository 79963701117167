import styled from "@emotion/styled"
import React from "react"
import { colors, fonts, device } from "../layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { PanelContainer } from "./Panel-Container"
import { Button } from "../layout/StyledComponents"
import { ModalBlock } from "../Modal"
import { modalText } from "../modalText"
import { Fancybox } from "../Fancy-Box"

const MainContainer = styled.section`
  background: ${({ light }) =>
    light ? `${colors.secondary101}` : `${colors.secondary203}`};
  padding: 72px 0px;
  .cover {
    width: 10px;
  }
  article {
    h2 {
      font-weight: 300;
      font-size: 36px;
      font-weight: 800;
      letter-spacing: -1.88px;
      line-height: 48px;
      margin: 30px 0px 20px;
      padding: 0px;
    }
    .buttons-container {
      display: flex;
      flex-direction: row;
      a:last-of-type {
        margin-left: 5px;
      }
    }
    p {
      font-size: 22px;
      font-weight: 400;
      letter-spacing: -0.69px;
      line-height: 36px;
      margin: 10px 0px 30px;
      padding: 0px;
    }
    span {
      color: ${colors.dark3};
      font-family: ${fonts.primary};
      font-size: 22px;
      font-weight: 400;
      letter-spacing: -0.69px;
      line-height: 36px;
      font-weight: 900;
    }
  }
  @media ${device.tablet} {
    img {
      height: 400px;
      width: 543px;
    }
    .buttons-container {
      display: flex;
      width: 343.3px;
      justify-content: space-between;
      flex-direction: row;
      a:last-of-type {
        margin-left: 15px;
      }
    }
  }
  @media ${device.laptop} {
    padding: 98px 0px 128px;
    article {
      h2 {
        margin-bottom: 15px;
        max-width: 400px;
        font-size: 40px;
        font-weight: 800;
        letter-spacing: -1.25px;
        line-height: 48px;
      }

      p {
        width: 513px;
      }
    }

    .buttons-container {
      display: flex;
      width: 423.3px;
      flex-direction: row;
      justify-content: space-between;
    }

    img {
      height: 400px;
      width: 543px;
    }
  }
  @media ${device.laptopL} {
    .buttons-container {
      width: 463.3px;
    }
    article {
      p {
        width: 553px;
      }
    }
    img {
      height: 400px;
      width: 543px;
    }
  }
`

export const KenyaPanel = ({ light }) => {
  return (
    <MainContainer light>
      <PanelContainer reverse>
        <StaticImage
          src="../../images/panels/kenya-trip.png"
          alt="group of people in Kenya"
          placeholder="none"
          quality={100}
        />
        <article>
          <h2>Experiential Journey to Kenya</h2>
          <span>January 6 - 16, 2023</span>
          <p>
            Our 10-day visit to Kenya allows you to connect to our culture and
            humanity, build lifetime bonds of friendship, and exchange skills
            with like-minded professionals in a familiar environment.
          </p>
          <div className="buttons-container">
            <ModalBlock modalText={modalText.programs.text}>
              <Button color="yellow">Learn More</Button>
            </ModalBlock>

            {/* <a
              href="https://vimeo.com/723239752"
              target="_blank"
              rel="noopener noreferrer"
            > */}
            <Fancybox className="cover" options={{ infinite: false }}>
              <Button
                data-fancybox="gallery"
                data-src="https://vimeo.com/723239752"
                backgroundFill
                color="yellow"
              >
                Watch video
              </Button>
            </Fancybox>
            {/* </a> */}
          </div>
        </article>
      </PanelContainer>
    </MainContainer>
  )
}
