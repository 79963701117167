import styled from "@emotion/styled"
import React, { useState } from "react"
import { colors, fonts, device } from "../layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { PanelContainer } from "./Panel-Container"
import { Tooltip } from "../Tooltip"

const tooltipText = [
  "We define these as Individuals or families living on less than US $1.90 per person per day in 2011 purchasing power parity (PPP) in the world's poorest countries. In lower-and upper-middle-income countries, $3.20 and $5.50 per person per day, respectively.",
]

const MainContainer = styled.section`
  background: ${colors.primary1};
  padding: 72px 0px;
  .hover {
    text-decoration: none;
    font-weight: 700;
  }
  .hover:hover {
    text-decoration: none;
    color: ${colors.primary2};
  }
  article {
    .par {
      font-size: 22px;
      font-weight: 400;
      letter-spacing: -0.69px;
      line-height: 36px;
    }

    span {
      font-family: ${fonts.primary};
      color: ${colors.dark3};
      font-size: 22px;
      font-weight: 400;
      letter-spacing: -0.69px;
      line-height: 36px;
      font-weight: 900;
    }
  }
  @media ${device.tablet} {
    img {
      width: 526.53px;
    }
  }
  @media ${device.laptop} {
    padding: 72px 0px 56px;
    article {
      margin-bottom: 30px;
    }
    .par {
      width: 480px;
    }
    img {
      width: 526.53px;
    }
  }
  @media ${device.laptopL} {
    article {
      margin-right: 00px;
    }
  }
`

export const SpiritualiTeaPanel = ({
  setToolTipMobile,
  toolTipMobile,
  hovered,
  setHovered,
}) => {
  const [name, setName] = useState("")
  const hoverAction = (bool, name) => {
    setName(name)
    setHovered(bool)
  }
  return (
    <MainContainer>
      <PanelContainer>
        <StaticImage
          src="../../images/panels/spirituali-tea-collage.png"
          alt="collage of young students"
          placeholder="none"
          quality={100}
        />
        <article>
          <p className="par">
            MLIFE began in 2017 to support children ages 13-17 to read
            critically, think creatively, write clearly, and speak confidently.
            In 2018, MLIFE began to empower high-potential and high-impacting
            youths ages 18-27 to realize their educational, career, and
            entrepreneurship goals through our 1-year experiential leadership
            program. MLIFE also supports community programs to aid families{" "}
            <span
              onClick={() => setToolTipMobile(active => !active)}
              onMouseOver={() => hoverAction(true, "living in the margins")}
              onMouseLeave={() => hoverAction(false, "")}
            >
              {name === "living in the margins" ? (
                <Tooltip
                  buttonText="View full glossary"
                  toolTipMobile={toolTipMobile}
                  setToolTipMobile={setToolTipMobile}
                  text={tooltipText[0]}
                  hovered={hovered}
                  setHovered={setHovered}
                >
                  <span className="hover">living in the margins</span>
                </Tooltip>
              ) : (
                <div className="special" style={{ display: "inline" }}>
                  {" "}
                  living in the margins
                </div>
              )}
            </span>{" "}
            in meeting their basic needs.
          </p>
          <span>We call our theory of change Spirituali-TEA.</span>
        </article>
      </PanelContainer>
    </MainContainer>
  )
}
