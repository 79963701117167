import styled from "@emotion/styled"
import React from "react"
import { device } from "./layout/GlobalStyles"
import { Button } from "./layout/StyledComponents"
import mlifeLogo from "../images/logos/logo-regular.svg"
import unSdgLogo from "../images/programs/logo-un.svg"
import rapidLogo from "../images/programs/logo-rapid.svg"
import spacesLogo from "../images/programs/logo-spaces.svg"
import ecclesiaLogo from "../images/programs/logo-ecclesia.svg"
import mloveLogo from "../images/programs/logo-mlove.svg"
import leapLogo from "../images/programs/logo-leap.svg"
import poverty from "../images/programs/no-poverty.svg"
import wellBeing from "../images/programs/good-health.svg"
import education from "../images/programs/quality-education.svg"
import peaceJustice from "../images/programs/peace-justice.svg"
import partnerships from "../images/programs/partnerships.svg"
import { ModalBlock } from "./Modal"
import { modalText } from "./modalText"

const MainContainer = styled.div`
  padding: 62px 0px 62px;
  section {
    h3 {
      font-size: 36px;
      font-weight: 800;
      margin-top: 0em;
      letter-spacing: -1.88px;
      line-height: 48px;
    }
  }
  @media ${device.tablet} {
    padding: 82px 0px 132px;
    section {
      h3 {
        font-size: 60px;
        font-weight: 800;
        letter-spacing: -1.88px;
        line-height: 65px;
        text-align: center;
        width: 450px;
        margin: 0px auto 50px;
      }
      p {
        text-align: center;
      }
      button {
        margin: 40px 0 00px 250px;
      }
    }
  }
  @media ${device.laptop} {
    padding: 128px 0px 94px;
    display: flex;
    justify-content: space-between;
    section {
      h3 {
        font-size: 60px;
        width: 100%;
        margin: 0 0 50px 0px;
        width: 453px;
        text-align: left;
      }
      p {
        font-weight: 500;
        text-align: left;
      }
      button {
        margin: 20px 0 00px 00px;
      }
    }
  }
`

const LogosContainer = styled.div`
  display: flex;
  justify-content: space-between;

  img {
    max-width: 140.3px;
  }
  div {
    height: 82px;
    width: 1px;
    background: black;
  }
  @media ${device.tablet} {
    max-width: 900px;
    margin: 0 auto;
  }
  @media ${device.laptop} {
    max-width: 369px;

    margin: 0px 0px 40px 0px;
  }
`

const PanelsContainer = styled.div`
  margin-top: 50px;
  position: relative;
  top: 10px;
  .image {
    position: relative;
    top: 20px;
  }
  .rapid-logo {
    position: relative;
    left: 14px;
  }
  .spaces-logo {
    position: relative;
    right: 12px;
  }
  .leap-logo {
    position: relative;
    right: 0px;
  }
  .ecclesia-logo {
    position: relative;
    left: 12px;
  }
  .mlove-logo {
    position: relative;
    left: 12px;
  }
  .divide {
    margin: 40px 0px;
  }
  @media ${device.tablet} {
    max-width: 400px;
    margin: 75px auto 0px;
  }
  @media ${device.laptop} {
    width: 340px;
    margin: 0px 80px 0px 0px;
  }
`

const Panel = styled.section`
  display: flex;
  position: relative;
  div {
    height: 90px;
    width: 1px;
    background: black;
    position: absolute;
    left: 50%;
  }
  img {
    min-width: 70.3px;
  }
  /* the images that comes directly after divs.. being as the container is a section tag 
  this is referring to the right side column images (square icons) */
  div + img {
    position: absolute;
    right: 0em;
  }
  @media ${device.tablet} {
    .logo {
      position: relative;
      top: 10px;
    }
  }
`

export const AlignPrograms = () => {
  return (
    <MainContainer className="container">
      <section>
        <h3>We align our programs with UN SDGs.</h3>
        <LogosContainer>
          <img className="mlife-logo" src={mlifeLogo} alt="MLIFE logo" />
          <div></div>
          <img className="un-sdg-logo" src={unSdgLogo} alt="UN SDG logo" />
        </LogosContainer>
        <p>MLIFE support(s) the Sustainable Development Goals. </p>
        <ModalBlock modalText={modalText.sdg.text}>
          <Button color="yellow">Learn more</Button>
        </ModalBlock>
      </section>
      <PanelsContainer>
        <Panel>
          <img className="rapid-logo" src={rapidLogo} alt="rapid logo" />
          <div></div>
          <img src={poverty} alt="no poverty" />
        </Panel>
        <Panel className="divide">
          <img
            className="logo spaces-logo"
            src={spacesLogo}
            alt="spaces of grace logo"
          />
          <div></div>
          <img src={wellBeing} alt="good health and well-being" />
        </Panel>
        <Panel className="divide">
          <img className="logo leap-logo" src={leapLogo} alt="leap logo" />
          <div></div>
          <img src={education} alt="quality education" />
        </Panel>
        <Panel className="divide">
          <img
            className="logo ecclesia-logo"
            src={ecclesiaLogo}
            alt="ecclessia logo"
          />
          <div></div>
          <img
            src={peaceJustice}
            alt="peace, justice and strong institutions"
          />
        </Panel>
        <Panel className="divide">
          <img className="logo mlove-logo" src={mloveLogo} alt="mlove logo" />
          <div></div>
          <img src={partnerships} alt="partnerships for goals" />
        </Panel>
      </PanelsContainer>
    </MainContainer>
  )
}
