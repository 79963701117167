import styled from "@emotion/styled"
import React, { useRef, useEffect, useState } from "react"
import { colors, fonts, device } from "../layout/GlobalStyles"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { PanelContainer } from "./Panel-Container"
import { Button } from "../layout/StyledComponents"
import { ModalBlock } from "../Modal"
import { modalText } from "../modalText"
import { Fancybox } from "../Fancy-Box"
import { useStaticQuery, graphql } from "gatsby"
import arrowGreen from "../../images/blog-arrow-green.svg"
import arrowRed from "../../images/blog-arrow-red.svg"

const MainContainer = styled.section`
  background: ${({ light }) => (light ? `#FFFAF1` : `#FFFAF1`)};
  padding: 102px 0px 00px;
  position: relative;

  .none {
    cursor: none;
  }
  .date-text-paragraph {
    color: ${colors.dark3};
    font-weight: 900;
  }
  .flex-container {
    margin: 0 auto;
  }
  .featured-image {
    border-radius: 15px;
    img {
      border-radius: 15px;
    }
  }
  .cover {
    width: 10px;
  }
  article {
    h2 {
      font-weight: 300;
      font-size: 36px;
      font-weight: 800;
      letter-spacing: -1.88px;
      line-height: 48px;
      margin: 30px 0px 20px;
      padding: 0px;
    }
    .buttons-container {
      display: flex;
      margin-top: 29px;
      padding-bottom: 72px;
      flex-direction: row;
      .second-button {
        margin-left: 20px;
      }
      a:last-of-type {
        margin-left: 20px;
      }
    }
    p {
      font-size: 22px;
      font-weight: 400;
      letter-spacing: -0.69px;
      line-height: 36px;
      margin: 10px 0px 10px;
      padding: 0px;
    }
    span {
      color: ${colors.dark3};
      font-family: ${fonts.primary};
      font-size: 22px;
      font-weight: 400;
      letter-spacing: -0.69px;
      line-height: 36px;
      font-weight: 900;
    }
  }
  @media ${device.tablet} {
    img {
      height: 400px;
      width: 543px;
    }
    .featured-image {
      img {
        height: 448px;
        width: 545px;
      }
    }
    .buttons-container {
      display: flex;
      width: 343.3px;
      justify-content: space-between;
      flex-direction: row;
      a:last-of-type {
        margin-left: 15px;
      }
    }
  }
  @media ${device.laptop} {
    padding: 98px 0px 0px;
    article {
      .buttons-container {
        padding-bottom: 128px;
      }
    }
    .panel-container {
    }
    .featured-image {
      align-self: flex-start;
      height: 400px;
      position: relative;
      top: 30px;
      width: 543px;
    }
    article {
      h2 {
        margin-bottom: 15px;
        max-width: 400px;
        margin-top: 0;
        font-size: 40px;
        font-weight: 800;
        letter-spacing: -1.25px;
        line-height: 48px;
      }
      span {
        max-width: 166px;
        background: teal;
      }
      p {
        width: 466px;
      }
    }
    .buttons-container {
      display: flex;

      width: 423.3px;
      flex-direction: row;
      justify-content: space-between;
    }
    img {
      height: 400px;
      width: 543px;
    }
  }
  @media ${device.laptopL} {
    padding: 64px 0 0;
    .buttons-container {
      width: 463.3px;
    }
    article {
      padding-bottom: 20px;
      position: relative;
      top: 20px;
      p {
        width: 466px;
      }
    }
    img {
      height: 580px;
      width: 533px;
    }
  }
`

const Outter = styled.div`
  position: relative;
  .inverse {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
`

const ButtonContainer = styled.div`
  .arrow-button-container {
    display: flex;
    justify-content: space-around;
    position: absolute;
    top: -60px;
    padding: 20px;
    border-radius: 12px 12px 12px 0px;
    width: 140px;
    z-index: 200;
    img {
      position: relative;
      top: 40px;
      cursor: pointer;
    }
  }
  width: 100%;
  z-index: 200;
  left: 0;
  img {
    width: 48px;
  }
  @media ${device.tablet} {
    .arrow-button-container {
      border-radius: 12px 12px 12px 12px;
      top: -50px;
      height: 60px;
      img {
        position: relative;
        top: 50px;
      }
    }
  }
  @media ${device.laptop} {
    width: 1112px;
    margin: 0 auto;
    .arrow-button-container {
      width: 163px;
    }
  }
`

const TryThis = styled.div`
  .spec,
  p {
    margin-top: 16px;
  }
  .top {
    padding-top: 32px;
  }
  .date-text {
    margin-top: 32px;
  }
`

export const AdPanelNoSlide = ({ ads, light }) => {
  const [index, setIndex] = useState(0)
  const [leftHovered, setLeftHovered] = useState(false)
  const [rightHovered, setRightHovered] = useState(false)

  const dateFormatter = frontmatter => {
    if (frontmatter.dates.displayformat == "Season") {
      return `${frontmatter.dates.seasondates.seasonstart} ${
        frontmatter.dates.seasondates.seasonyearstart
          ? frontmatter.dates.seasondates.seasonyearstart
          : ""
      }  ${frontmatter.dates.seasondates.seasonend ? "-" : ""}  ${
        frontmatter.dates.seasondates.seasonend
          ? frontmatter.dates.seasondates.seasonend
          : ""
      } ${
        frontmatter.dates.seasondates.seasonyearend
          ? frontmatter.dates.seasondates.seasonyearend
          : ""
      } `
    }
    if (frontmatter.dates.displayformat == "Specific") {
      // t
      let monthStart =
        frontmatter.dates.specificdates.specificstartdate.split(" ")[0]
      let dayStart =
        frontmatter.dates.specificdates.specificstartdate.split(" ")[1]
      let yearStart =
        frontmatter.dates.specificdates.specificstartdate.split(" ")[2]
      if (dayStart[0] === "0") dayStart = `${dayStart[1]},`
      let finalStart = `${monthStart} ${dayStart} ${yearStart}`

      let monthEnd =
        frontmatter.dates.specificdates.specificenddate.split(" ")[0]
      let dayEnd = frontmatter.dates.specificdates.specificenddate.split(" ")[1]
      let yearEnd =
        frontmatter.dates.specificdates.specificenddate.split(" ")[2]
      if (dayEnd[0] === "0") dayEnd = `${dayEnd[1]},`

      let finalEnd = `${monthEnd} ${dayEnd} ${yearEnd}`
      return `${finalStart} ${
        frontmatter.dates.specificdates.specificenddate == "Invalid date"
          ? ""
          : "-"
      } ${
        frontmatter.dates.specificdates.specificenddate == "Invalid date"
          ? ""
          : finalEnd
      } `
    }
    if (frontmatter.dates.displayformat == "Month") {
      return `${frontmatter.dates.monthdates.monthstart?.substring(0, 3)} ${
        frontmatter.dates.monthdates.yearstart
      } ${frontmatter.dates.monthdates.monthend ? "-" : ""} ${
        frontmatter.dates.monthdates.monthend
          ? `${frontmatter.dates.monthdates.monthend.substring(0, 3)}  `
          : ""
      } ${
        frontmatter.dates.monthdates.yearend
          ? `${frontmatter.dates.monthdates.yearend}`
          : ""
      }`
    }
  }
  const text = (data, frontmatter) => {
    return (
      <TryThis>
        <h5>{frontmatter.title}</h5>
        <div className="date">
          <p style={{ fontWeight: "900" }}>{dateFormatter(frontmatter)}</p>
        </div>
        <div className="body" dangerouslySetInnerHTML={{ __html: data }}></div>

        <a target="_blank" rel="noreferrer" href={frontmatter.modalbuttonlink}>
          <Button style={{ marginTop: "32px" }} color="yellow">
            {frontmatter.modalbuttontext}
          </Button>
        </a>
      </TryThis>
    )
  }
  return (
    <Outter>
      <MainContainer light={light}>
        {/* <button className="left-button testimonial-slider-prev">prev</button>
      <button className="left-button testimonial-slider-next">next</button> */}
        {ads.map(({ node, node: { frontmatter } }, i) => {
          return (
            <PanelContainer
              className="panel-container"
              style={{ position: "relative" }}
              reverse
            >
              {/* <StaticImage
                  src="../../images/panels/kenya-trip.png"
                  alt="group of people in Kenya"
                  placeholder="none"
                  quality={100}
                /> */}
              <GatsbyImage
                className="featured-image"
                image={getImage(
                  frontmatter.featured_image.childrenImageSharp[0]
                )}
                alt="frontal shot of person"
              />
              <article>
                <h2>{frontmatter.title}</h2>
                <p className="date-text-paragraph">
                  {dateFormatter(frontmatter)}
                </p>
                <p>{frontmatter.blurb}</p>
                <div className="buttons-container">
                  <ModalBlock modalText={text(node.html, frontmatter)}>
                    <Button color="yellow">Learn more</Button>
                  </ModalBlock>

                  {/* <a
              href="https://vimeo.com/723239752"
              target="_blank"
              rel="noopener noreferrer"
            > */}
                  {frontmatter.videolink && (
                    <Fancybox className="cover" options={{ infinite: false }}>
                      <Button
                        className="second-button"
                        data-fancybox="gallery"
                        data-src={`${frontmatter.videolink}`}
                        backgroundFill
                        color="yellow"
                      >
                        Watch video
                      </Button>
                    </Fancybox>
                  )}
                  {/* </a> */}
                </div>
              </article>
            </PanelContainer>
          )
        })}
      </MainContainer>
      {/* here you commented out this becuase of no slider buttons */}
      {/* <ButtonContainer light={light}>
        <div className="arrow-button-container">
          <div className="prev">
            <img
              style={{ opacity: ".7", cursor: "default" }}
              className="none"
              src={arrowGreen}
              alt="disabled previous ad"
            />
          </div>
          <div className="">
            <img
              style={{ opacity: ".7", cursor: "default" }}
              className="none inverse"
              src={arrowGreen}
              alt="disabled previous ad"
            />
          </div>
        </div>
      </ButtonContainer> */}
    </Outter>
  )
}
